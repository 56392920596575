import React from 'react'
import './footer.css'

const Footer = (props) => {
    const navigateTo = (path) => {
        // Navigate to the desired page
        window.location.href = path;
    };

    return (
        <div className='footer-container'>
            <div className='footer-list-container'>
                <div className='footer-group'>
                    <p className='footer-group-title'>Product</p>
                    <ul className='footer-list'>
                        <li onClick={() => navigateTo('/')} className='footer-list-item'>Home</li>
                        <li onClick={() => navigateTo('/pricing')} className='footer-list-item'>Pricing</li>
                        <li onClick={() => navigateTo('/reports/sample')} className='footer-list-item'>Sample Report</li>
                    </ul>
                </div>
                <div className='footer-group'>
                    <p className='footer-group-title'>Company</p>
                    <ul className='footer-list'>
                        <li onClick={() => navigateTo('/about-us')} className='footer-list-item'>About Us</li>
                        <li onClick={() => navigateTo('/privacy')} className='footer-list-item'>Privacy Policy</li>
                        <li onClick={() => navigateTo('/contact-us')} className='footer-list-item'>Contact Us</li>
                        <li onClick={() => navigateTo('/terms')} className='footer-list-item'>Terms of Service</li>
                        <li onClick={() => navigateTo('/refund')} className='footer-list-item'>Refund Policy</li>
                    </ul>
                </div>
            </div>
            <div className='copyright-container'>
                <p className='copyright-text'>Copyright ©Vintrust 2024</p>
            </div>
        </div>
    )
}

export default Footer
