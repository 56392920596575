import {Route, Routes, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import ReactGA from "react-ga";
import Header from "./common/header";
import Global from "./global";
import Report from "./report/report";
import SampleReport from "./report/sampleReport";
import Pricing from "./pricing/pricing";
import ContactUs from "./contactus/contactUs";
import AboutUs from "./common/about/aboutUs";
import TermsAndConditions from "./common/terms/terms";
import Refund from "./common/refund/refund";
import Privacy from "./common/privacy/privacy";
import ErrorPage from "./common/errorPage";
import NotFound from "./common/notfound/notFound";
import Footer from "./common/footer";
import CookieConcent from "./common/cookieconcent/cookieConcent";
import Cookies from "js-cookie";

const App = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    useEffect(() => {
        const consent = Cookies.get('consent');
        if (consent === 'accepted') {
            loadGoogleAnalytics();
        } else {
            loadGoogleAnalyticsAnonymous();
        }
    }, []);

    const loadGoogleAnalytics = () => {
        if (window.gtag) return;
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-DYP8JF8PVX`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', 'G-DYP8JF8PVX', {
                'anonymize_ip': true,
                'allow_ad_personalization_signals': false
            });
        };
    };

    const loadGoogleAnalyticsAnonymous = () => {
        if (window.gtag) return;
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-DYP8JF8PVX`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', 'G-DYP8JF8PVX', {
                'anonymize_ip': false,
                'allow_ad_personalization_signals': true
            });
        };
    };

    return (
        <React.StrictMode>
            <div className='main-container'>
                <Header></Header>
                <div className='app-container'>
                    <Routes>
                        <Route path="/" element={<Global/>}/>
                        <Route path="/reports/:vin" element={<Report/>}/>
                        <Route path="/reports/sample" element={<SampleReport/>}/>
                        <Route path="/pricing" element={<Pricing/>}/>
                        <Route path="/contact-us" element={<ContactUs/>}/>
                        <Route path="/about-us" element={<AboutUs/>}/>
                        <Route path="/terms" element={<TermsAndConditions/>}/>
                        <Route path="/refund" element={<Refund/>}/>
                        <Route path="/privacy" element={<Privacy/>}/>
                        <Route path="/error" element={<ErrorPage/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    <CookieConcent></CookieConcent>
                </div>
                <Footer></Footer>
            </div>
        </React.StrictMode>
    );
}

export default App;