import React from 'react';

import './pricing.css'
import Search from "../search/search";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const Pricing = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='pricing-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <h1 className='pricing-title'>Pricing</h1>
            <div className="pricing-block">
                <h2 className="pricing-block-text">Transparent Pricing</h2>
                <p className="pricing-block-text">Get peace of mind with our straightforward pricing. No hidden fees or
                    surprises.</p>
                <div className="price-info">
                    <p className="price">$4.99</p>
                    <p className="price-label">Per Report</p>
                </div>
                <p className="pricing-note">* All prices are in USD</p>
            </div>
            <Search containerStyles={{'marginTop': '50px'}}></Search>
        </div>
    );
};

export default Pricing;