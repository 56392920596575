import React from 'react'
import Search from './search/search'

import "./global.css"
import {Helmet} from "react-helmet";

const Global = (props) => {
    const canonicalUrl = `https://vintrust.pro`;

    return (
        <div className='global-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <Search containerStyles={ {'marginTop': '50px'} }></Search>
            <div className='main-page-info-container'>

                <div className='global-container-item'>
                    <div className='global-text-container'>
                        <h3 className='global-text-title'>See Beyond the Paint</h3>
                        <p className='global-text-description'>There's more to a vehicle than meets the eye. Look beyond
                            the
                            shiny exterior with our VIN decoding service, revealing past incidents like airbag
                            deployments
                            and frame damage. See beyond the paint and make a decision you can trust.</p>
                    </div>
                    <div className='global-image-container'>
                        <img className='global-image' src="/img/beyond-the-paint.png" alt="Verify a vehicle's safety"/>
                    </div>
                </div>

                <div className='global-container-item'>
                    <div className='global-image-container'>
                        <img className='global-image' src="/img/vehicles-safety.jpeg" alt="Verify a vehicle's safety"/>
                    </div>
                    <div className='global-text-container'>
                        <h3 className='global-text-title'>Verify a vehicle's safety</h3>
                        <p className='global-text-description'>Ensure the safety of you and your family by knowing the
                            actual
                            facts about a vehicle's past. Decode vin of your vehicle and find concealed car accidents, inflated airbags, and suspension
                            problems
                            can cause life-threatening consequences.</p>
                    </div>
                </div>

                <div className='global-container-item'>
                    <div className='global-text-container'>
                        <h3 className='global-text-title'>Avoid costly scams</h3>
                        <p className='global-text-description'>Mileage rollbacks, road accidents, and other hidden details may cost thousands in vehicle repairs.
                            Avoid bad quality used cars, motorcycles, and other vehicle deals by checking their history using our detailed reports.
                            <a href="/reports/sample"> See a sample report</a> to understand how we can help you make informed decisions and protect your investment.</p>
                    </div>
                    <div className='global-image-container'>
                        <img className='global-image' src="/img/costly-scams.png" alt="Verify a vehicle's safety"/>
                    </div>
                </div>

                <div className='global-container-item'>
                    <div className='global-image-container'>
                        <img className='global-image' src="/img/best-deal.jpg" alt="Verify a vehicle's safety"/>
                    </div>
                    <div className='global-text-container'>
                        <h3 className='global-text-title'>Not just saving time</h3>
                        <p className='global-text-description'>Armed with accurate information, you'll have the upper
                            hand
                            when negotiating with sellers. Whether it's uncovering hidden issues or leveraging
                            maintenance
                            history, our reports empower you to secure the best possible price.
                            By obtaining a comprehensive vehicle history report,
                            you can uncover hidden issues that may not be visible during a visual inspection.</p>
                    </div>
                </div>
            </div>

            <div className='find-vin-container'>
                <h3 className='find-vin-title'>Where can I find the VIN?</h3>
                <div className='find-vin-images'>
                    <div className='find-vin-item'>
                        <img src="/img/1-vin.png" alt="Where to find VIN"/>
                        <div className='find-vin-description'>
                            <p className='find-vin-small-text'>On the dashboard, under the windshield</p>
                        </div>
                    </div>
                    <div className='find-vin-item'>
                        <img src="/img/2-vin.png" alt="Where to find VIN"/>
                        <div className='find-vin-description'>
                        <p className='find-vin-small-text'>On the driver's side door frame</p>
                        </div>
                    </div>
                    <div className='find-vin-item'>
                        <img src="/img/3-vin.png" alt="Where to find VIN"/>
                        <div className='find-vin-description'>
                        <p className='find-vin-small-text'>On the engine, under the hood</p>
                        </div>
                    </div>
                    <div className='find-vin-item'>
                        <img src="/img/4-vin.png" alt="Where to find VIN"/>
                        <div className='find-vin-description'>
                        <p className='find-vin-small-text'>On the vehicle registration documents</p>
                        </div>
                    </div>
                    <div className='find-vin-item'>
                        <img src="/img/5-vin.png" alt="Where to find VIN"/>
                        <div className='find-vin-description'>
                        <p className='find-vin-small-text'>Ask the seller</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Global
