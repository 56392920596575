import {v4 as uuidv4} from 'uuid';

const API_URL = process.env.REACT_APP_API_ENDPOINT || 'https://api.vintrust.pro';

const fetchToken = async (vin, tokenType) => {
    const firstUUID = uuidv4();
    const secondUUID = uuidv4();

    const c = Math.floor(Math.random() * 32) + 1;
    const r = Math.floor(Math.random() * 16) + 1;

    const clientToken = `${vin}${firstUUID}8e6${secondUUID.slice(0, c)}`;
    const clientTokenParam = btoa(clientToken);

    const response = await fetch(`${API_URL}/auth/token?clientToken=${clientTokenParam}&type=${tokenType}&c=${c}&r=${r}`);
    if (!response.ok) {
        throw new Error('Failed to fetch token');
    }
    return await response.json();
};

export default fetchToken;