import React from 'react';

import './privacy.css'
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";


const TermsAndConditions = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='privacy-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className='privacy-text-container'>
                <h1 className='privacy-title-header-text'>Privacy notice about data processing and cookies for users of
                    the website </h1>
                <h3 className='privacy-header-text'>1. Introduction</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        1.1. At Vin Trust, we prioritize privacy and personal data security. This Privacy Notice
                        outlines
                        our approach to handling vehicle data and contains essential information for your understanding.
                    </p>

                    <p className='privacy-list-item'>
                        1.2. We strive for transparency in our data practices. While we cannot directly link vehicle
                        data to
                        specific owners, we are committed to providing relevant information and maintaining the highest
                        level of transparency.
                    </p>

                    <p className='privacy-list-item'>
                        1.3. Throughout this notice, "You" refers to the owner or holder of the vehicle from which we
                        collect data.
                    </p>

                    <p className='privacy-list-item'>
                        1.4. Please note that this Privacy Notice may be subject to updates, which will be posted on our
                        website or mobile application.
                    </p>

                    <p className='privacy-list-item'>
                        1.5. If you use our services, we encourage you to review the privacy notice for visitors and
                        users
                        of our platform.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>2. Data Controllers</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        2.1. Vehicle data is controlled by Vin Trust, acting as joint controllers under Article 26(1) of
                        the
                        GDPR.
                    </p>

                    <p className='privacy-list-item'>
                        2.2. For any queries regarding data protection, please contact us at vintrust@consultant.com.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>3. Purposes and Legal Basis for Data Processing</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        3.1. We process vehicle data to establish a transparent used vehicle market, protecting buyers
                        from
                        fraud and enhancing road safety.
                    </p>

                    <p className='privacy-list-item'>
                        3.2. Our processing activities are based on legitimate interests, aiming to provide clear
                        information,
                        protect buyers from fraud, and improve market transparency.
                    </p>

                    <p className='privacy-list-item'>
                        3.3. Additionally, we use vehicle data for AI training, performance analysis, and dispute
                        resolution,
                        complying with legal obligations.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>4. Personal Data Processed</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        4.1. Vehicle data processed includes identification, technical parameters,
                        service and event
                        records,
                        financial data, and other relevant information.
                    </p>

                    <p className='privacy-list-item'>
                        4.2. Our reports focus on vehicle information and do not include data on owners or holders.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>5. Data Recipients and Transmission</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        5.1. Aggregated vehicle data is provided to customers who request it.
                    </p>

                    <p className='privacy-list-item'>
                        5.2. We may engage data processors for various services, ensuring data protection and compliance
                        with
                        legal requirements.
                    </p>

                    <p className='privacy-list-item'>
                        5.3. Data may be disclosed to law enforcement or dispute resolution authorities as necessary by
                        law.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>6. Data Sources</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        6.1. Data is sourced from official registers, service providers, dealers, and publicly available
                        information.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>7. Data Retention Period</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        7.1. Personal data is retained for as long as necessary, typically up to 30 years from
                        acquisition.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>8. Automated Data Processing</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        8.1. We employ automated solutions for data analysis and report generation, ensuring fairness
                        and
                        impartiality.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>9. Your Rights</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        9.1. You have rights to access, rectify, erase, restrict processing, and object to data
                        processing.
                        You
                        can lodge a complaint with the supervisory authority.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>10. Use of Cookies</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        10.1.We do not directly collect cookies on our website. Therefore, we do not track users'
                        browsing behavior
                        or collect
                        information about their activities on the internet.
                    </p>
                    <p className='privacy-list-item'>
                        10.2. We use Google Analytics, a web analytics service provided by Google LLC ("Google"), to
                        analyze traffic and usage patterns on our website. Google Analytics operates on the basis of
                        your consent to the use of cookies.
                    </p>
                    <p className='privacy-list-item'>
                        10.3. We have configured Google Analytics to anonymize IP addresses ("anonymize_ip") in case of
                        Consent absence, ensuring
                        that no personally identifiable information is collected. This means that your IP address is
                        anonymized before being stored by Google Analytics.
                    </p>
                    <p className='privacy-list-item'>
                        10.4. For more information on how Google Analytics collects and processes data, as well as how
                        you can control the information sent to Google, please refer to Google's Privacy Policy and
                        Google Analytics Opt-out Browser Add-on.
                    </p>
                </ol>

                <h3 className='privacy-header-text'>11. Contact Us</h3>

                <ol className='privacy-list'>
                    <p className='privacy-list-item'>
                        11.1. For any inquiries or data-related issues, contact us at vintrust@consultant.com.
                    </p>
                </ol>

                <p className='privacy-last-update-text'>Date of Last Update: 09/03/2024</p>
            </div>
        </div>
    );
};

export default TermsAndConditions;