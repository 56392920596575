import React from 'react'

import "./searchResult.css"
import checkmark from "./img/checkmark-flat.png";

const SearchResult = ({decodedVin, isFound, isSearching, vin}) => {
    if (!isFound && vin.length > 0 && !isSearching) {
        return (
            <div className='search-result-not-found-title-container'>
                <p className='search-result-not-found-title'>Sorry, the VIN entered doesn't match any records in our
                    database.</p>
                <p className='search-result-not-found-title'>Please double-check it or try another one.</p>
            </div>

        );
    }

    if (isFound && !isSearching) {
        return (
            <div className='search-result-container'>
                <div className='search-result-title-container'>
                    <div className='search-result-big-title-container'>
                        <img className="search-result-big-title-image" src={checkmark} alt=''></img>
                        <p className='search-result-title'>Success!</p>
                    </div>
                    <p className='search-result-title-small'>We've found information on this vehicle!</p>
                </div>
                <div className="search-result-info-container">
                    {(decodedVin.brand || decodedVin.country) && (
                        <div className="search-result-info-section">
                            {decodedVin.brand && (
                                <div className='search-result-info-section-item'>
                                    Brand: <p className='search-result-info-text'>{decodedVin.brand}</p>
                                </div>
                            )}

                            {decodedVin.country && (
                                <div className='search-result-info-section-item'>
                                    Country: <p className='search-result-info-text'>{decodedVin.country}</p>
                                </div>
                            )}
                        </div>
                    )}

                    {(decodedVin.year || decodedVin.model) && (
                        <div className="search-result-info-section">
                            {decodedVin.year && (
                                <div className='search-result-info-section-item'>
                                    Year: <p className='search-result-info-text'>{decodedVin.year}</p>
                                </div>
                            )}

                            {decodedVin.model && (
                                <div className='search-result-info-section-item'>
                                    Model: <p className='search-result-info-text'>{decodedVin.model}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {decodedVin.image && decodedVin.image !== "/img/no-img.png" && (
                    <div className='search-result-image-container'>
                        <img className='search-result-image' src={decodedVin.image} alt="Car icon"/>
                    </div>
                )}
            </div>
        );
    }
}


export default SearchResult
