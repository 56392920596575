import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import fetchToken from './../common/auth/auth';
import log from 'loglevel';

import "./report.css"
import ReportContent from "./report-content";
import ReportLoader from "./reportLoader";
import {Helmet} from "react-helmet";

const API_URL = process.env.REACT_APP_API_ENDPOINT || 'https://api.vintrust.pro';

const Report = (props) => {
    const {vin} = useParams(); //get the URL parameters
    const [report, setReport] = useState({});
    const [loading, setLoading] = useState(true);

    const canonicalUrl = `https://vintrust.pro/reports/${vin}`;

    const navigate = useNavigate();

    const fetchReport = async (token) => {
        const response = await fetch(`${API_URL}/report/${vin}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    };

    useEffect(() => {
        // window.gtag('event', 'conversion', {
        //     'send_to': 'AW-841271423/5L-VCJjBxsoZEP-Qk5ED',
        //     'transaction_id': vin
        // });

        const fetchData = async () => {
            try {
                const tokenResponse = await fetchToken(vin, 'FETCH_REPORT');
                const token = tokenResponse.token;
                const reportData = await fetchReport(token);
                setReport(reportData);
                setLoading(false);
            } catch (error) {
                log.error(error, 'Error calling report API')
                setLoading(false);
                navigate('/error'); // Redirect to error page
            }
        };
        fetchData().then(r => log.info(vin, 'Success calling report API'));
    }, [vin, navigate]);

    return (
        <div className='report-wrapper'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            {loading ? (
                <ReportLoader/>
            ) : (
                <ReportContent report={report} vin={vin}/>
            )}
        </div>
    )
}

export default Report
