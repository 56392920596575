import React from 'react'

import baseInfo from './img/base-info.png'
import theft from './img/theft-records.png'
import maintenance from './img/car-maintenance.png'
import odometer from './img/odometer.png'
import damage from './img/damages.png'
import setup from './img/setup.png'
import info from './img/car-info.png'
import engine from './img/car-engine.png'
import dimensions from './img/dimensions.png'
import './fullReport.css'

const FullReportView = (props) => {

  const navigateTo = (path) => {
    // Navigate to the desired page
    window.location.href = path;
  };

  return (
      <div className="full-report-container">
        <div className="full-report-title-container">
          <h1 className='full-report-container-title'>In full report we check:</h1>
        </div>
        <div className="full-report-items-container">
          <div className="full-report-item">
            <img className="full-report-item-image" src={theft} alt=''></img>
            <p>Theft records</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={baseInfo} alt=''></img>
            <p>Basic vehicle information</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={maintenance} alt=''></img>
            <p>Maintenance</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={odometer} alt=''></img>
            <p>Odometer</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={damage} alt=''></img>
            <p>Damages</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={setup} alt=''></img>
            <p>Vehicle setup</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={info} alt=''></img>
            <p>Other useful information</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={engine} alt=''></img>
            <p>Engine details</p>
          </div>
          <div className="full-report-item">
            <img className="full-report-item-image" src={dimensions} alt=''></img>
            <p>Vehicle dimensions</p>
          </div>
        </div>
        <input className="nav-button" onClick={() => navigateTo('/reports/sample')} value="Check our sample report"/>
      </div>
  )
}

export default FullReportView