import React from 'react'
import './price.css'


const ReportPriceView = ({price}) => {

    return (
        <div className="price-container">
            <div className='discounted-price-container'>
                <div className="style-1">
                    <del>
                        <span className="discounted-price-old-amount">$9.99</span>
                    </del>
                    <ins>
                        <span className="discounted-price-new-amount">$4.99</span>
                    </ins>
                </div>
                <p className='discounted-price-text'>Buy now and check your car!</p>
            </div>
            <div className='final-price-container'>
                <p className="buy-full-report"> {price ? `$${price}` : '$4.99'}</p>
                <p className='buy-full-report-small-title'>/ full report</p>
            </div>
            {/*<div className="price-small-container">*/}
            {/*  <p>Price:</p>*/}
            {/*  <p>{price ? `${price}$` : '4.99'}</p>*/}
            {/*</div>*/}
        </div>
    )
}

export default ReportPriceView