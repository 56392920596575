import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import './cookieConcent.css'

const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('consent');
        if (!consent) {
            setShowBanner(true);
        } else if (consent === 'accepted') {
            loadGoogleAnalytics();
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('consent', 'accepted', {expires: 365});
        setShowBanner(false);
        loadGoogleAnalytics();
    };

    const handleReject = () => {
        Cookies.set('consent', 'rejected', {expires: 365});
        setShowBanner(false);
    };

    const loadGoogleAnalytics = () => {
        if (window.gtag) return;
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-DYP8JF8PVX`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];

            function gtag() {
                window.dataLayer.push(arguments);
            }

            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', 'G-DYP8JF8PVX', {
                'anonymize_ip': false,
                'allow_ad_personalization_signals': true
            });
        };
    };

    return (
        showBanner && (
            <div className="cookie-banner">
                <div className='cookie-banner-image-container'>
                    <img className='cookie-image' src="/img/cookie_icon.png" alt="Cookie consent"/>
                    <p className='cookie-consent-title'>Cookies Consent</p>
                </div>
                <p>We use cookies to enhance your experience on our website. By continuing to use our website, you
                    consent to our use of cookies. <a href="/privacy">Learn more</a></p>
                <div className="buttons">
                    <button id="acceptBtn" className='button' onClick={handleAccept}>Accept All Cookies</button>
                    <button id="declineBtn" className='button' onClick={handleReject}>Reject All Cookies</button>
                </div>
            </div>
        )
    );
};

export default CookieConsent;