import React from 'react';
import './error.css'
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";

const ErrorPage = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='error-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className='error-small-container'>
                <h1 className='error-big-text'>Oops!</h1>
                <div className="horizontal-line"></div>
                <div className='error-small-text-container'>
                    <h2 className='error-text'>Something went wrong and your page could not be loaded.</h2>

                    <div className='error-contact-us-container'>
                        <p className='error-text-contact-us'>Please try again or</p>
                        <a className='error-text-contact-us' href="/contact-us">contact us</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;