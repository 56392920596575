import React from 'react';

import './terms.css'
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";


const Terms = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='terms-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className='terms-small-container'>

                <h1 className='privacy-title-header-text'>Terms & Conditions</h1>
                <h2 className='terms-header-text'>VinTrust, an online platform dedicated to providing
                    comprehensive vehicle history reports, operates with a commitment to transparency and user
                    satisfaction. Below are the key provisions and guidelines for using VinTrust and its services</h2>
                <h3 className='terms-header-text'>1. General Provisions</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        1.1. VinTrust is managed and administered by VinTrust Group, LLC, headquartered at 123 Main
                        Street,
                        Anytown, USA (referred to as "VinTrust").
                    </p>

                    <p className='terms-list-item'>
                        1.2. By accessing and utilizing VinTrust.com and its services, users implicitly agree to adhere
                        to the
                        terms and conditions outlined herein.
                    </p>
                </ol>

                <h3 className='terms-header-text'>2. Definitions</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        2.1. Various terms used within these provisions are clearly defined to ensure clarity and
                        understanding
                        for users.
                    </p>
                </ol>

                <h3 className='terms-header-text'>3. Account</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        3.1. Users may need to create an account to access certain services on VinTrust.com.
                        Account creation methods include email registration, activation through provided links, or
                        utilizing
                        third-party account credentials such as Facebook, Google, or Apple.
                    </p>
                </ol>

                <h3 className='terms-header-text'>4. Report</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        4.1. Users can purchase credits to obtain vehicle history reports or directly enter a vehicle
                        identification number (VIN) for an instant report.
                    </p>

                    <p className='terms-list-item'>
                        4.2. Reports provide comprehensive information sourced from various databases and sources,
                        aiding users
                        in making informed decisions regarding vehicle purchases.
                    </p>
                </ol>

                <h3 className='terms-header-text'>5. Responsibilities of the User</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        5.1. Users agree to use VinTrust.com and its services responsibly, refraining from any activity
                        that may
                        compromise the platform's integrity or violate its terms.
                    </p>

                    <p className='terms-list-item'>
                        5.2. Prohibited activities include attempting unauthorized access, using automated tools, or
                        engaging in
                        unlawful behavior.
                    </p>
                </ol>


                <h3 className='terms-header-text'>6. Services</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        6.1.When utilizing our VIN decoding service, users gain access to essential vehicle
                        identification information extracted from VIN numbers. This service encompasses the development,
                        deployment, and maintenance of software systems and APIs designed to facilitate VIN data
                        extraction and processing.
                    </p>

                </ol>

                <h3 className='terms-header-text'>7. Payment Classification</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        7.1. Payments made for our VIN decoding service are classified as remuneration for data
                        processing
                        provisions. This categorization is crucial for tax considerations and may influence the tax
                        treatment of
                        transactions conducted with us.
                    </p>
                    {/*<p className='terms-list-item'>*/}
                    {/*    7.1. Payments made for our VIN decoding service are classified as remuneration for software*/}
                    {/*    development*/}
                    {/*    provisions. This categorization is crucial for tax considerations and may influence the tax*/}
                    {/*    treatment of*/}
                    {/*    transactions conducted with us.*/}
                    {/*</p>*/}

                </ol>

                <h3 className='terms-header-text'>8. User Interaction</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        8.1. Although end-users interact with the service by entering VINs into input fields and
                        downloading reports,
                        the underlying technical work involved in creating and maintaining the software infrastructure
                        for VIN
                        decoding constitutes software development services.
                    </p>

                </ol>


                <h3 className='terms-header-text'>9. Protection of Users</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        9.1. Users are provided with transparent information regarding the pricing, validity, and terms
                        of
                        services.
                    </p>
                </ol>


                <h3 className='terms-header-text'>10. Personal Data Protection</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        10.1. VinTrust adheres to strict privacy policies outlined in the Privacy Policy accessible on
                        VinTrust.com.
                    </p>
                    <p className='terms-list-item'>
                        10.2. Users' personal data is handled with care and in compliance with relevant regulations.
                    </p>
                </ol>

                <h3 className='terms-header-text'>11. Change or Termination of Services</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        11.1. VinTrust reserves the right to modify services, pricing, or terms, with adequate notice
                        provided to
                        users.
                    </p>
                    <p className='terms-list-item'>
                        11.2. Restrictions or termination of user access may occur in cases of policy violation or legal
                        requirements.
                    </p>
                </ol>

                <h3 className='terms-header-text'>12. Liability</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        12.1. VinTrust assumes no liability for inaccuracies in reports or content sourced from
                        third-party
                        websites.
                    </p>
                    <p className='terms-list-item'>
                        12.2. Users are responsible for decisions made based on the information provided by
                        VinTrust.com.
                    </p>
                </ol>


                <h3 className='terms-header-text'>13. Intellectual Property Rights</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        13.1. All intellectual property associated with VinTrust.com is protected and may not be used
                        without
                        explicit permission.
                    </p>
                </ol>


                <h3 className='terms-header-text'>14. Applicable Law and Dispute Resolution</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        14.1. Disputes are subject to resolution in accordance with applicable laws and jurisdiction.
                    </p>
                </ol>

                <h3 className='terms-header-text'>15. Information Protection Measures</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        15.1. Users are encouraged to maintain the confidentiality of their account information, and
                        VinTrust
                        implements measures to safeguard user data.
                    </p>
                </ol>

                <h3 className='terms-header-text'>16. Final Provisions</h3>

                <ol className='terms-list'>
                    <p className='terms-list-item'>
                        16.1. These provisions constitute the entire agreement between users and VinTrust, superseding
                        any prior
                        agreements or understandings.
                    </p>
                    <p className='terms-list-item'>
                        16.2. Users and VinTrust retain their respective rights and obligations as outlined in these
                        provisions.
                    </p>
                </ol>

                <p className='terms-last-update-text'>Date of Last Update: 10/03/2024</p>
            </div>
        </div>
    );
};

export default Terms;