import React from 'react';
import {PayPalButtons} from '@paypal/react-paypal-js';


const PayPalButton = ({ currency, amount, onSuccess, onError }) => {
    return (
        <PayPalButtons
            className="custom-paypal-buttons"
            style={{ disableMaxWidth: true }}
            createOrder={(data, actions) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: currency,
                                value: amount,
                            },
                        },
                    ],
                });
            }}
            onApprove={(data, actions) => {
                return actions.order.capture().then(function (details) {
                    onSuccess(details);
                });
            }}
            onError={onError}
        />
    );
};

export default PayPalButton;