import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import fetchToken from './../common/auth/auth';

import log from 'loglevel';

import './search.css';
import './../payment-form.css';
import SearchResult from "./../searchResult";
import FullReportView from "../full-report-view";
import ReportPriceView from "./../report-price-view";

import {useNavigate} from "react-router-dom"
import Loader from "./searchLoader";

import PayPalButton from "../common/paypal/PayPalButton";
import thinCheckmarkIcon from "../img/checkmark-thin-icon.png";
import warningIcon from "../img/caution-icon.png";

const API_URL = process.env.REACT_APP_API_ENDPOINT || 'https://api.vintrust.pro';
console.log(API_URL)
const Search = ({showTitle, snowDescription, titleText, descriptionText, containerStyles}) => {

    const {register, handleSubmit} = useForm();
    const navigate = useNavigate()
    const [inputValidationError, setInputValidationError] = useState('');

    const handlePaymentSuccess = (details) => {
        log.info(details, 'Paypal payment API response')
        console.log(details)
        console.log('payment success')
        //call api that issues token, with which it's possible to call
        //reports/{vin} only
        // window.gtag('event', 'conversion', {
        //     'send_to': 'AW-841271423/5L-VCJjBxsoZEP-Qk5ED',
        //     'transaction_id': decodedVin.vin
        // });

        navigate(`/reports/${decodedVin.vin}`)
    };

    const handlePaymentError = (err) => {
        console.error('Payment error:', err);
        // Handle payment error
    };

    const onSubmit = data => {
        if (data.vin.length === 17) {
            setVin(data.vin)
            setInputValidationError('');
            handleSubmit(onSubmit)
        } else {
            setInputValidationError('*Enter correct VIN');
        }

    }

    const [vin, setVin] = useState('');
    const [isSearching, setIsSearching] = useState(false)
    const [isFound, setIsFound] = useState(false)
    const [decodedVin, setDecodedVin] = useState('')
    const [reportPrice, setReportPrice] = useState('');

    const searchVin = async () => {
        if (vin.length > 0) {
            setIsSearching(true);
            try {
                // Fetch the token
                const tokenResponse = await fetchToken(vin, 'SEARCH');
                const token = tokenResponse.token; // Assuming token is returned as a property named 'token'
                // Call the /vin/search endpoint with the token
                const vinResponse = await fetch(`${API_URL}/vin/search/${vin}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                });

                if (vinResponse.status === 401) {
                    setIsSearching(false);
                    setIsFound(false);
                    setDecodedVin(null);
                    setInputValidationError('*Something went wrong, try again later')
                }

                if (vinResponse.status === 404) {
                    setIsSearching(false);
                    setIsFound(false);
                    setDecodedVin(null);
                    setInputValidationError('*VIN not found in our database')
                }

                const response = await vinResponse.json();
                setDecodedVin(response);
                setIsFound(true);
                setIsSearching(false);

            } catch (error) {
                log.info(vin, 'Error calling search API')
                if (error.message === 'VIN not found') {
                    console.log(`VIN ${vin} not found`);
                } else {
                    // Handle other errors
                    setIsSearching(false);
                    setIsFound(false);
                    setDecodedVin(null);
                }
            }
        }
    };


    useEffect(() => {
        fetch(
            `${API_URL}/payments/report-price`,
            {
                method: "GET",
                headers: new Headers({})
            }
        )
            .then(res => res.json())
            .then(response => {
                log.debug(vin, 'Report price API response' + JSON.stringify(response))
                setReportPrice(response.price)
            })
            .catch(error => {
                log.error(vin, 'Error calling search API')
            });
    }, []);

    useEffect(() => {
        searchVin().then(r => log.info(vin, 'Success calling search API'));
    }, [vin]);


    const shouldShowElement = (shouldShow) => {
        return shouldShow === undefined || shouldShow ? {} : {display: "none"};
    }

    return (
        <div className="search-container" style={containerStyles}>
            <h1 className="visually-hidden-title">Vintrust | Choose the right car</h1>
            <h2 className="search-title" style={shouldShowElement(showTitle)}>
                {titleText ? titleText : "Helping you choose the right vehicle"}
            </h2>
            <h3 className="search-description" style={shouldShowElement(snowDescription)}>
                {descriptionText ? descriptionText : "Avoid costly problems by checking a vehicle's history."} <br/>
                <span>Enter a VIN to get a detailed report instantly.</span>
            </h3>

            <form className="search-form" onSubmit={handleSubmit(onSubmit)} method='POST'>
                <label className="search-form-label">
                    <p className="search-label-text">Enter VIN number:</p>
                    <div className='input-error-container'>
                        <input
                            className={`search-input ${inputValidationError.length > 0 ? 'invalid' : ''}`} {...register("vin")}
                            type="text" name="vin"
                            placeholder="1VWBN7A35CCXXXXXX"
                        />
                        <p className='input-error'>{inputValidationError}</p>
                    </div>
                </label>
                <input className="search-button" type="submit" value="Search"/>
            </form>


            {isSearching &&
                <Loader/>
            }

            {isFound && !isSearching &&
                <SearchResult decodedVin={decodedVin} isFound={isFound} isSearching={isSearching} vin={vin}/>
            }

            {isFound && !isSearching &&
                <div className='search-result-extras'>
                    <p className='search-result-extras-title'>Looks like there are records about:</p>
                    <div className='search-result-extras-internal-container'>
                        <div className='search-result-extras-item'>
                            <img className='search-result-extras-img' src={warningIcon} alt=""/>
                            <p className='search-result-extras-text'>Damages</p>
                        </div>
                        <div className='search-result-extras-item'>
                            <img className='search-result-extras-img' src={warningIcon} alt=""/>
                            <p className='search-result-extras-text'>Mileage altering</p>
                        </div>
                        <div className='search-result-extras-item'>
                            <img className='search-result-extras-img' src={warningIcon} alt=""/>
                            <p className='search-result-extras-text'>Theft</p>
                        </div>
                        <div className='search-result-extras-item'>
                            <img className='search-result-extras-img' src={thinCheckmarkIcon} alt=""/>
                            <p className='search-result-extras-text'>Vehicle usage</p>
                        </div>
                        <div className='search-result-extras-item'>
                            <img className='search-result-extras-img' src={warningIcon} alt=""/>
                            <p className='search-result-extras-text'>Maintenance</p>
                        </div>
                        <div className='search-result-extras-item'>
                            <img className='search-result-extras-img' src={thinCheckmarkIcon} alt=""/>
                            <p className='search-result-extras-text'>Owners</p>
                        </div>
                    </div>
                </div>
            }

            { isFound && !isSearching &&
               <div className='damage-warning-container'>
                   <p className='damage-warning-main-text'>Damage warning</p>
                   <p className='damage-warning-medium-text'>This vehicle may appear damaged.</p>
                   <p className='damage-warning-small-text'>We recommend checking the full report to avoid hidden problems.</p>
               </div>
            }

            {decodedVin && !isSearching && isFound &&
                <ReportPriceView price={reportPrice}/>}

            {isFound && !isSearching &&
                <PayPalButton currency="USD"
                              amount="4.99"
                              onSuccess={handlePaymentSuccess}
                              onError={handlePaymentError}/>
            }

            {isFound && !isSearching &&
                <div className='call-to-action-container'>
                    <p className='call-to-action-text'>Want to know more?</p>
                </div>
            }

            {decodedVin && !isSearching && isFound &&
                <FullReportView/>
            }
        </div>
    )
}

export default Search
