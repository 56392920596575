import React from 'react'
import './header.css'
import BurgerMenu from "./burgerMenu";
import useNavigationHighLight from "./useNavigationHighLight";


const Header = (props) => {
    useNavigationHighLight();

    const navigateTo = (path) => {
        // Navigate to the desired page
        window.location.href = path;
    };

    return (
        <div className='header-container'>
            <div className='header-logo-container' onClick={() => navigateTo('/')}>
                <img className='header-logo-img' src="/img/logo.svg" alt="Vintrust | Choose the right car"/>
                <p className='logo-text'>VIN TRUST</p>
            </div>
            <BurgerMenu></BurgerMenu>
            <div className='header-list-container'>
                <ul className='header-list'>
                    <li className='header-list-item' onClick={() => navigateTo('/reports/sample')}
                        data-path="/reports/sample">Sample report
                    </li>
                    <li className='header-list-item' onClick={() => navigateTo('/pricing')}
                        data-path="/pricing">Pricing
                    </li>
                    <li className='header-list-item' onClick={() => navigateTo('/contact-us')}
                        data-path="/contact-us">Contact us
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header
