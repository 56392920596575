import React from 'react';

import './contactUs.css'
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const ContactUs = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='contact-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className='contact-small-container'>
                <img className='contact-cloud-img' src="/img/cloud.png" alt="Contact Vintrust team"/>
                <h1 className='contact-us-title'>Contact Customer Support</h1>
                <p className='contact-text'>We are constantly striving to make our products and services better. In case
                    of a concern or feedback, please let us know.</p>
                <p className='contact-email'>vintrust@consultant.com</p>
            </div>
        </div>
    );
};

export default ContactUs;