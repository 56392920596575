import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ReportContent from "./report-content";
import ReportLoader from "./reportLoader";
import {Helmet} from "react-helmet";

const API_URL = process.env.REACT_APP_API_ENDPOINT || 'https://api.vintrust.pro';

const SampleReport = ({ props }) => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;

    const {vin} = useParams(); //get the URL parameters

    const [report, setReport] = useState({});
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        window.history.scrollRestoration = 'manual';

        fetch(`${API_URL}/report/sample/W0LPD8ED9G8039368`, {
            method: "GET",
            headers: new Headers({})
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then(response => {
                setReport(response);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);
                navigate('/error'); // Redirect to error page
            });

        return () => {
            window.history.scrollRestoration = 'auto';
        };
    }, [vin, navigate]);

    return (
        <div className='sample-report-wrapper'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            {loading ? (
                <ReportLoader/>
            ) : (
                <ReportContent report={report} vin={vin}/>
            )}
        </div>
    )
}

export default SampleReport;