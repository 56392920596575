import React from 'react';

import './refund.css'
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";


const Refund = () => {
    const location = useLocation();
    const canonicalUrl = `https://vintrust.pro${location.pathname}`;
    return (
        <div className='refund-container'>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <div className='refund-small-container'>
                <h1 className='privacy-title-header-text'>Refund Policy of VinTrust</h1>
                <h2 className='refund-header-text'>
                    At VinTrust, we strive to provide accurate and reliable vehicle information to our customers.
                    However, we understand that circumstances may arise where a refund is necessary. Please review the
                    following refund policy.</h2>

                <h3 className='refund-header-text'>1. Refund Eligibility</h3>

                <ol className='refund-list'>
                    <p className='refund-list-item'>
                        Refunds are eligible under the following circumstances:
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Duplicate purchase: If you accidentally purchase the same VIN decoding service multiple times.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Technical issues: In the event of a technical error or malfunction preventing access to the
                        decoded
                        information.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Inaccurate or incomplete report: If the decoded information provided is significantly
                        inaccurate or
                        incomplete.
                    </p>
                </ol>

                <h3 className='refund-header-text'>2. Refund Process</h3>

                <ol className='refund-list'>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • To request a refund, please contact our customer support team within 14 days of the original
                        purchase
                        date.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Provide details of the issue encountered and any supporting documentation, such as screenshots
                        or error
                        messages.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Our customer support team will review your request and determine eligibility for a refund.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • If approved, refunds will be issued to the original payment method used for the purchase.
                    </p>
                </ol>

                <h3 className='refund-header-text'>3. Non-Refundable Items</h3>

                <ol className='refund-list'>
                    <p className='refund-list-item'>
                        Certain items are non-refundable, including:
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • VIN decoding services that have been successfully delivered and accessed by the customer.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Services purchased using promotional discounts, unless otherwise stated in the promotion
                        terms.
                    </p>
                </ol>

                <h3 className='refund-header-text'>4. Contact Us</h3>

                <ol className='refund-list'>
                    <p className='refund-list-item'>
                        Certain items are non-refundable, including:
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • For refund requests or further assistance, please contact our customer support team at
                        vintrust@consultant.com.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Our support operator will get back to you withing 24 hours.
                    </p>
                </ol>

                <h3 className='refund-header-text'>5. Policy Updates</h3>

                <ol className='refund-list'>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • VinTrust reserves the right to update or modify this refund policy at any time without prior
                        notice.
                    </p>
                    <p className='refund-list-item refund-list-item-with-padding'>
                        • Any changes to the policy will be effective immediately upon posting on our website.
                    </p>
                </ol>

                <p className='refund-last-update-text'>By using our VIN decoding services, you agree to abide by the
                    terms and conditions outlined in this refund policy. If you have any questions or require
                    clarification on any aspect of this policy, please do not hesitate to contact us. We appreciate your
                    understanding and cooperation.</p>
                <p className='refund-last-update-text'>Date of Last Update: 10/03/2024</p>
            </div>
        </div>
    );
};

export default Refund;