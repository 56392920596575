import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const handleNavigationHighlight = () => {
    const currentPath = window.location.pathname;
    const menuItems = document.querySelectorAll('.header-list-item');

    // Loop through each menu item and remove the active class if it exists
    menuItems.forEach(item => {
        item.classList.remove('active');
    });

    // Find the menu item corresponding to the current page and add the active class
    menuItems.forEach(item => {
        if (item.getAttribute('data-path') === currentPath) {
            item.classList.add('active');
        }
    });
};

const useNavigationHighlight = () => {
    const location = useLocation();

    useEffect(() => {
        // Call the function initially
        handleNavigationHighlight();

        // Listen for changes to the URL and update the navigation highlight accordingly
        window.addEventListener('popstate', handleNavigationHighlight);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handleNavigationHighlight);
        };
    }, [location]);
};

export default useNavigationHighlight;