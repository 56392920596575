import './burgerMenu.css'

import Hamburger from 'hamburger-react'
import React, {useEffect, useState} from "react";
import useNavigationHighlight from "./useNavigationHighLight";

const BurgerMenu = () => {
    const [isOpen, setOpen] = useState(false)

    const navigateTo = (path) => {
        // Navigate to the desired page
        window.location.href = path;
        setOpen(false); // Close the menu after navigation
    };

    useNavigationHighlight();

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isOpen]);

    const toggleMenu = () => {
        setOpen(!isOpen); // Toggle the isOpen state
    };


    return (
        <div className='burger-menu'>
            <Hamburger toggled={isOpen} toggle={toggleMenu}/>
            <ul className={`burger-menu-list ${isOpen ? 'open' : ''}`}>
                <li className='header-list-item' onClick={() => navigateTo('/')}
                    data-path="/">Home
                </li>
                <li className='header-list-item' onClick={() => navigateTo('/reports/sample')}
                    data-path="/reports/sample">Sample report
                </li>
                <li className='header-list-item' onClick={() => navigateTo('/pricing')} data-path="/pricing">Pricing
                </li>
                <li className='header-list-item' onClick={() => navigateTo('/contact-us')}
                    data-path="/contact-us">Contact us
                </li>
                <li className='header-list-item' onClick={() => navigateTo('/about-us')}
                    data-path="/about-us">About us
                </li>
                <li className='header-list-item-hidden'>Hidden element</li>
            </ul>
        </div>
    );
}

export default BurgerMenu