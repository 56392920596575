import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import log from 'loglevel';
import ReactGA from 'react-ga';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'normalize.css';
import './index.css';
import reportWebVitals  from './reportWebVitals';

import App from './app';

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || "Aan5LsG5inKLN0CP_NVfrFEvGjmb-uq14JLbBzQwp2kDEONfYii7STPy9nNoiu0UhG9ltwned-dqcJ0a";
// console.log(PAYPAL_CLIENT_ID);

// Initialize Google Analytics with your tracking ID
ReactGA.initialize('G-DYP8JF8PVX');

if (process.env.NODE_ENV === 'production') {
    log.setLevel('warn');
} else {
    log.setLevel('debug');
}

if (typeof document !== 'undefined') {
    const initialOptions = {
        clientId: PAYPAL_CLIENT_ID,
        currency: "USD"
    };
    ReactDOM.hydrate(
        <BrowserRouter>
            <PayPalScriptProvider options={initialOptions}>
                <App />
            </PayPalScriptProvider>
        </BrowserRouter>,
        document.getElementById('root')
    );
}

reportWebVitals();