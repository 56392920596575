import React, {useRef} from 'react';
import mileageIcon from "./img/mileage-icon.png";
import warningIcon from "../img/warning-icon.png";
import checkmarkIcon from "../img/checkmark-flat.png";
import taxiIcon from "./img/taxi-icon.png";
import stolenIcon from "./img/stolen-icon.png";
import accidentIcon from "./img/accident-icon.png";
import carModel from "./img/car-model.jpeg";
import yellowWarningIcon from "../img/yellow-warning-icon.png";
import thinCheckmarkIcon from "../img/checkmark-thin-icon.png";
import mileageRollbackIcon from "../img/mileage-rollback-icon.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ReportContent = ({report, vin}) => {

    const divToPrintRef = useRef();

    const createAndDownloadPdf = () => {
        const input = divToPrintRef.current;

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait', // or 'landscape'
                    unit: 'mm',
                    format: [canvas.width * 0.264583, canvas.height * 0.264583], // set format to captured content size
                });
                pdf.addImage(imgData, 'JPEG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
                if (vin) {
                    pdf.save(vin + '-report.pdf');
                } else if (window.location.pathname === '/reports/sample') {
                    pdf.save('sample-report.pdf');
                } else {
                    pdf.save('vin-check-report.pdf');
                }
            });
    };

    return (
        <div className='report-and-download-container'>
            <div className='report-container' ref={divToPrintRef}>
                <div className='report-container-main-section'>
                    <div className='report-title-container'>
                        <p className='car-name-title'> {report.summary?.make} {report.summary?.model}</p>
                        <p className='car-vin-name'>VIN NUMBER</p>
                        <p className='car-vin-value'>{report.summary?.vin}</p>
                    </div>
                </div>

                <div className='your-car-overview-wrapper'>

                    <div className='your-car-overview'>
                        <h1 className='your-car-overview-title'>
                            YOUR CAR AT A GLANCE
                        </h1>
                    </div>

                    <div className='your-car-overview-content-container'>
                        <div className='your-car-overview-content-item'>
                            <div>
                                <img src={mileageIcon} alt="Report mileage icon"/>
                            </div>
                            <div className='your-car-overview-name-container'>
                                {report.summary?.isOdometerAltered &&
                                    <img className='general-icon' src={warningIcon} alt="Warning: pay attention to this property"/>}
                                {!report.summary?.isOdometerAltered &&
                                    <img className='general-icon' src={checkmarkIcon} alt="Success: check is clean"/>}

                                <p className='your-car-overview-big-text'>Mileage</p>
                            </div>
                            {renderOdometerAlteredCheck(report.summary?.isOdometerAltered)}
                        </div>
                        <div className='your-car-overview-content-item'>
                            <div>
                                <img src={taxiIcon} alt="Report taxi icon"/>
                            </div>
                            <div className='your-car-overview-name-container'>
                                {report.summary?.isUsedInTaxi &&
                                    <img className='general-icon' src={warningIcon} alt="Warning: pay attention to this property"/>}
                                {!report.summary?.isUsedInTaxi &&
                                    <img className='general-icon' src={checkmarkIcon} alt="Success: check is clean"/>}

                                <p className='your-car-overview-big-text'>Taxi</p>
                            </div>
                            {renderTaxiCheck(report.summary?.isUsedInTaxi)}
                        </div>
                        <div className='your-car-overview-content-item'>
                            <div>
                                <img src={stolenIcon} alt="Report stolen icon"/>
                            </div>
                            <div className='your-car-overview-name-container'>
                                {report.summary?.isStolen &&
                                    <img className='general-icon' src={warningIcon} alt="Warning: pay attention to this property"/>}
                                {!report.summary?.isStolen &&
                                    <img className='general-icon' src={checkmarkIcon} alt="Success: check is clean"/>}

                                <p className='your-car-overview-big-text'>Theft</p>
                            </div>
                            {renderStolenCheck(report.summary?.isStolen)}
                        </div>
                        <div className='your-car-overview-content-item'>
                            <div>
                                <img src={accidentIcon} alt="Report accident icon"/>
                            </div>
                            <div className='your-car-overview-name-container'>
                                {report.summary?.isDamaged &&
                                    <img className='general-icon' src={warningIcon} alt="Warning: pay attention to this property"/>}
                                {!report.summary?.isDamaged &&
                                    <img className='general-icon' src={checkmarkIcon} alt="Success: check is clean"/>}

                                <p className='your-car-overview-big-text'>Accidents</p>
                            </div>
                            {renderDamagedCheck(report.summary?.isDamaged)}
                        </div>
                    </div>

                </div>

                <div className='additional-info-container'>
                    <div className='additional-info-title'>
                        <h3>Additional information</h3>
                    </div>

                    {report?.summary?.country && (<div className='additional-info-item'>
                        <div className='additional-info-title-container'>
                            <p>Country:</p>
                        </div>
                        <div className='additional-info-value-container'>
                            <p>{report?.summary?.country}</p>
                        </div>
                    </div>)}

                    <div className='additional-info-item'>
                        <div className='additional-info-title-container'>
                            <p>Serial number:</p>
                        </div>
                        <div className='additional-info-value-container'>
                            <p>{report?.summary?.serialNumber}</p>
                        </div>
                    </div>


                </div>

                <div className='usage-and-ownership-container'>
                    <div className='usage-and-ownership-title'>
                        <h3>Vehicle usage and ownership restrictions</h3>
                    </div>

                    {report?.usageAndOwnership?.map(item =>
                        <div key={item.id} className={buildUsageAndOwnershipContainerStyle(item.id)}>
                            <div className='usage-and-ownership-item-label'>
                                <p>{item.label}</p>
                            </div>
                            <div className='usage-and-ownership-item-value'>
                                {isUsageAndOwnerShipRecordFound(item.isFound)}
                                <p style={item.isFound ? {color: 'red'} : {}}>{item.value}</p>
                            </div>
                        </div>
                    )}
                </div>

                <div className='mileage-wrapper'>

                    <div className='mileage-title'>
                        <h3>Mileage</h3>
                    </div>

                    {report?.mileage?.chronology?.length > 0 && (
                        <div className={buildMileageContainerStyle(0)}>
                            <div className='mileage-item-label mileage-header-text'>
                                <p>Date recorded</p>
                            </div>
                            <div className='mileage-item-value mileage-header-text'>
                                <p>{report?.mileage?.measurement} recorded</p>
                            </div>
                        </div>
                    )}

                    {report?.mileage?.chronology?.map(item =>
                        <div key={item.id} className={buildMileageContainerStyle(item.id)}>
                            <div className='mileage-item-label'>
                                <p>{item.date}</p>
                            </div>
                            <div className='mileage-item-value'>
                                <p style={item.rollbackSuspected ? {color: 'red'} : {}}>{item.value}</p>
                                {isMileageRollbackSuspected(item.rollbackSuspected)}
                            </div>
                        </div>
                    )}

                    <div className='mileage-facts-title'>
                        <p>Facts about this vehicle’s mileage</p>
                    </div>

                    <div className='mileage-facts-container'>
                        {report?.mileage?.estimatedMileage > 0 && (
                            <div className='mileage-facts-item'>
                                <div className='mileage-facts-title-container'>
                                    <p>As of today, the estimated mileage of {vin} could be:</p>
                                </div>
                                <div className='mileage-facts-value-container'>
                                    <p>{report?.mileage?.estimatedMileage}</p>
                                </div>
                            </div>
                        )}
                        {report?.mileage?.similarModelMileage > 0 && (
                            <div className='mileage-facts-item'>
                                <div className='mileage-facts-title-container'>
                                    <p>As of today, the average mileage of
                                        identical {report.summary?.make} {report.summary?.model} vehicles is:</p>
                                </div>
                                <div className='mileage-facts-value-container'>
                                    <p>{report?.mileage?.similarModelMileage}</p>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className='mileage-checks-title'>
                        <h3>Mileage checks</h3>
                    </div>

                    {report?.mileage?.odometerChecks?.map(item =>
                        <div key={item.id} className={buildMileageChecksContainerStyle(item.id)}>
                            <div className='mileage-checks-label'>
                                <p>{item.label}</p>
                            </div>
                            <div className='mileage-checks-value'>
                                {isOdometerCheckDetected(item.isDetected)}
                                <p style={item.isDetected ? {color: 'red'} : {}}>{item.value}</p>
                            </div>
                        </div>
                    )}
                </div>

                <div className='damage-container'>
                    <div className='damage-big-title'>
                        <h3>Damage</h3>
                    </div>

                    <div className='damage-content-container'>

                        <div className='damage-description-container'>
                            <div className='damage-description-item'>
                                <div className='damage-description-item-label'><p>Summary:</p></div>
                                <div className='damage-description-item-value'>
                                    {report?.damage?.isDamaged === false && (
                                        <>
                                            <p>Not damaged</p>
                                            {isDamagedStatus(report?.damage?.isDamaged)}
                                        </>
                                    )}
                                    {report?.damage?.isDamaged && report?.damage?.damageCount === 1 && (
                                        <>
                                            <p className='damage-status-value-damaged'>Damaged at least once</p>
                                            {isDamagedStatus(report?.damage?.isDamaged)}
                                        </>
                                    )}
                                    {report?.damage?.isDamaged && report?.damage?.damageCount === 2 && (
                                        <>
                                            <p className='damage-status-value-damaged'>Damaged at least twice</p>
                                            {isDamagedStatus(report?.damage?.isDamaged)}
                                        </>
                                    )}
                                    {report?.damage?.isDamaged && report?.damage?.damageCount > 2 && (
                                        <>
                                            <p className='damage-status-value-damaged'>Damaged at
                                                least {report.damage.damageCount} times</p>
                                            {isDamagedStatus(report?.damage?.isDamaged)}
                                        </>
                                    )}
                                    {report?.damage?.isDamaged && report?.damage?.damageCount <= 0 && (
                                        <>
                                            <p className='damage-status-value-damaged'>Damaged vehicle</p>
                                            {isDamagedStatus(report?.damage?.isDamaged)}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='damage-description-item pale-background'>
                                <div className='damage-description-item-label'><p>Location:</p></div>

                                {report?.damage?.isDamaged && report?.damage?.location && (
                                    <div className='damage-description-item-value'>
                                        <p>{report?.damage?.location}</p>
                                    </div>
                                )}

                                {report?.damage?.isDamaged && !report?.damage?.location && (
                                    <div className='damage-description-item-value'>
                                        <p>Unknown</p>
                                    </div>
                                )}

                                {!report?.damage?.isDamaged && (
                                    <div className='damage-description-item-value'>
                                        <p>N/A</p>
                                    </div>
                                )}
                            </div>
                            <div className='damage-description-item'>
                                <div className='damage-description-item-label'><p>Estimated repair costs:</p></div>

                                <div className='damage-description-item-value'>
                                    {report?.damage?.isDamaged ? (
                                        <p>Not provided</p>
                                    ) : (
                                        <p>N/A</p>
                                    )}
                                </div>
                            </div>
                            <div className='damage-description-item pale-background'>
                                <div className='damage-description-item-label'><p>Possible damage type:</p></div>
                                <div className='damage-description-item-value'>
                                    {report?.damage?.isDamaged ? (
                                        <p>Collision Damage</p>
                                    ) : (
                                        <p>N/A</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='damage-model-image-container'>
                            <img src={carModel} alt="Car image mockuo" className='damage-model-img'/>
                        </div>

                    </div>
                </div>

                <div className='damage-additional-records-container'>
                    <div className='damage-additional-records-title'>
                        <h3>Damage-related records</h3>
                    </div>

                    {report?.damage?.damageTypes?.map(item =>
                        <div key={item.id} className={buildDamageContainerStyle(item.id)}>
                            <div className='damage-additional-records-label'>
                                <p>{item.label}</p>
                            </div>
                            <div className='damage-additional-records-value'>
                                {isDamageProblemFound(item.isFound)}
                                <p style={item.isFound ? {color: 'red'} : {}}>{item.value}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='download-container'>
                <button className='pdf-download-button' onClick={createAndDownloadPdf}> Download Report</button>
            </div>
        </div>
    );
};

function buildDamageContainerStyle(id) {
    if (id % 2 === 0) {
        return "damage-additional-records-items pale-background";
    } else {
        return "damage-additional-records-items";
    }
}

function buildMileageChecksContainerStyle(id) {
    if (id % 2 === 0) {
        return "mileage-checks-container pale-background";
    } else {
        return "mileage-checks-container";
    }
}

function buildMileageContainerStyle(id) {
    if (id % 2 === 0) {
        return "mileage-item pale-background";
    } else {
        return "mileage-item";
    }
}

function buildUsageAndOwnershipContainerStyle(id) {
    if (id % 2 === 0) {
        return "usage-and-ownership-item pale-background";
    } else {
        return "usage-and-ownership-item";
    }
}

function isUsageAndOwnerShipRecordFound(isFound) {
    if (isFound) {
        return <img src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
    } else {
        return <img src={thinCheckmarkIcon} alt="Success: check is clean"/>
    }
}

function isOdometerCheckDetected(isFound) {
    if (isFound) {
        return <img src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
    } else {
        return <img src={thinCheckmarkIcon} alt="Success: check is clean"/>
    }
}

function isDamageProblemFound(isFound) {
    if (isFound) {
        return <img src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
    } else {
        return <img src={thinCheckmarkIcon} alt="Success: check is clean"/>
    }
}

function isDamagedStatus(isFound) {
    if (isFound) {
        return <img className="damaged-status-image" src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
    } else {
        return <img className='not-damaged-status-image' src={thinCheckmarkIcon} alt="Success: check is clean"/>
    }
}

function isMileageRollbackSuspected(isRollbackSuspected) {
    if (isRollbackSuspected) {
        return <div className='odometer-rollback-warning-container'>
            <img src={mileageRollbackIcon} alt="Report mileage rollback icon"/>
            <p className='odometer-rollback-warning-container-text'>Odometer rollback detected!</p>
        </div>
    }
}

function renderDamagedCheck(isDamaged) {
    if (isDamaged) {
        return <div className='your-car-overview-description-container'>
            <img className='general-icon' src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
            <p className='your-car-overview-small-text'>This vehicle was damaged.</p>
        </div>
    }
    return <div className='your-car-overview-description-container'>
        <p className='your-car-overview-small-text'>This vehicle was not damaged.</p>
    </div>
}


function renderStolenCheck(isStolen) {
    if (isStolen) {
        return <div className='your-car-overview-description-container'>
            <img className='general-icon' src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
            <p className='your-car-overview-small-text'>This vehicle is wanted as stolen.</p>
        </div>
    }
    return <div className='your-car-overview-description-container'>
        <p className='your-car-overview-small-text'>This vehicle is not wanted as stolen.</p>
    </div>
}

function renderTaxiCheck(isUsedInTaxi) {
    if (isUsedInTaxi) {
        return <div className='your-car-overview-description-container'>
            <img className='general-icon' src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
            <p className='your-car-overview-small-text'>This vehicle was used as a taxi.</p>
        </div>
    }
    return <div className='your-car-overview-description-container'>
        <p className='your-car-overview-small-text'>This vehicle was not used as a taxi.</p>
    </div>
}

function renderOdometerAlteredCheck(isStolen) {
    if (isStolen) {
        return <div className='your-car-overview-description-container'>
            <img className='general-icon' src={yellowWarningIcon} alt="Warning: pay attention to this property"/>
            <p className='your-car-overview-small-text'>The mileage of this vehicle may be tampered.</p>
        </div>
    }
    return <div className='your-car-overview-description-container'>
        <p className='your-car-overview-small-text'>The mileage of this vehicle was not tampered.</p>
    </div>
}

export default ReportContent;